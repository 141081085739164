import { createContext } from 'react';

export interface TenantFeatures {
  enableCronofyScheduling: boolean;
  enableNonIntegratedScheduling: boolean;
}

/**
 * We parse the tenant data object in order to provide a cleaner configuration object where possible.
 */
export interface TenantConfiguration {
  name: string;
  slug: string;
  features: TenantFeatures;
  cognito: {
    clientId: string;
    userPoolId: string;
  };
}

export const TenantConfigContext = createContext<TenantConfiguration | null>(
  null
);

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  type FunctionComponent,
} from 'react';
import { useRouter } from 'next/router';
import { useSessionStorage } from '@mantine/hooks';
import { TaskRelatedObject } from '@/lib/graphql/types';
import { objectForPathname } from '@/lib/utils/path-object';

type StoredDeepLinkToken = {
  token: string;
} & (
  | {
      type: TaskRelatedObject;
      identifier: string | null;
    }
  | {
      type: null;
      identifier: string;
    }
);

interface DeepLinkAuthContextType {
  tokens: StoredDeepLinkToken[];
  clearTokens: () => void;
  addObjectToken(
    token: string,
    type: TaskRelatedObject,
    identifier: string | null
  ): void;
  addPathToken(token: string, path: string): void;
}

const DeepLinkAuthContext = createContext<DeepLinkAuthContextType>({
  tokens: [],
  // This context provider defaults to silent stub functions, so as to allow
  // for components to support it from apps or routes that don't support deep linking
  clearTokens: () => {},
  addObjectToken: () => {},
  addPathToken: () => {},
});

interface DeepLinkAuthorizationProviderProps extends PropsWithChildren {
  testToken?: string;
  testTokenPath?: string;
}

export const DeepLinkAuthorizationProvider: FunctionComponent<
  DeepLinkAuthorizationProviderProps
> = ({ children, testToken, testTokenPath = '/' }) => {
  const [tokens, setTokens, clearStorage] = useSessionStorage<
    StoredDeepLinkToken[]
  >({
    key: 'deepLinkTokens',
    defaultValue: testToken
      ? [{ token: testToken, type: null, identifier: testTokenPath }]
      : [],
  });

  /**
   * Clear all tokens from local storage
   */
  const clearTokens = useCallback(() => {
    clearStorage();
  }, [clearStorage]);

  /**
   * Add a token associated with a task associated object
   */
  const addObjectToken = useCallback(
    (token: string, type: TaskRelatedObject, identifier: string | null) => {
      setTokens((currentTokens) => [
        ...currentTokens,
        {
          token,
          type,
          identifier,
        },
      ]);
    },
    [setTokens]
  );

  /**
   * Add a token associated with a simple path.
   * We just store this in the same structure with a `null` type.
   */
  const addPathToken = useCallback(
    (token: string, path: string) => {
      setTokens((currentTokens) => [
        ...currentTokens,
        {
          token,
          type: null,
          identifier: path,
        },
      ]);
    },
    [setTokens]
  );

  return (
    <DeepLinkAuthContext.Provider
      value={{
        tokens,
        clearTokens,
        addObjectToken,
        addPathToken,
      }}>
      {children}
    </DeepLinkAuthContext.Provider>
  );
};

/**
 * Access Deep Link authorization tokens for the current route.
 * @returns A JWT, if one is set for the current route.
 */
export const useDeepLinkAuthorization = () => {
  const { tokens, clearTokens, addObjectToken, addPathToken } =
    useContext(DeepLinkAuthContext);
  const { isReady, pathname, query } = useRouter();

  // Look up the object type and ID from the well-known feature path.
  // If no object is found, we look for a token associated with the path itself.
  const { objectType, objectId } = objectForPathname(pathname, query) ?? {};

  const tokenObject = objectType
    ? tokens.find(
        (_) =>
          _.type === objectType &&
          (_.identifier === objectId || _.identifier === null)
      )
    : tokens.find((_) => _.identifier && pathname.startsWith(_.identifier));
  const token = tokenObject?.token ?? null;

  return {
    loading: !isReady,
    token,
    hasToken: !!token,
    logOut: () => clearTokens(),
    addObjectToken,
    addPathToken,
  };
};

// istanbul ignore file
// Ignoring file as it is a pure configuration file.
import { createTheme, colorsTuple } from '@mantine/core';

const caireTheme = createTheme({
  // Enabled support for reduced motion: This was true by default in Mantine 6,
  // but disabled in 7. We also use enabling of the reduced motion setting in
  // our tests in order to have various elements not be unavailable due on animation
  // timing issues.
  respectReducedMotion: true,
  colors: {
    brand: colorsTuple('var(--color-brandPrimary)'),
  },
  primaryColor: 'brand',
  fontFamily: 'var(--fontFamily)',
  defaultRadius: 'sm',
  components: {
    Badge: {
      defaultProps: {
        radius: 'xl',
      },
    },
    Button: {
      defaultProps: {
        size: 'md',
        radius: 'xl',
      },
    },
    Checkbox: {
      defaultProps: {
        variant: 'filled',
        radius: 'xl',
      },
    },
    Drawer: {
      defaultProps: {
        // We had this override set in the Mantine 7 days, so it remains.
        withinPortal: false,
      },
    },
    Table: {
      defaultProps: {
        withTableBorder: true,
        withRowBorders: true,
      },
    },
    Portal: {
      defaultProps: {
        // Ensure that Portals are created as children of the theme-root, so that
        // font-family and other styles are inherited from the theme.
        target: '.theme-root',
      },
    },
  },
});

export default caireTheme;

import { useDeepLinkAuthorization } from '@/contexts/DeepLinkAuthorization';
import { useCognitoSession } from '@/contexts/Cognito';
import { useEffect, useState } from 'react';

export enum AuthenticationSource {
  UserAccountAuthentication,
  DeepLink,
  None,
}

interface AuthenticationState {
  isLoading: boolean;
  isReady: boolean;
  authenticated: boolean;
  authToken: string | null;
  hasToken: boolean;
  error: Error | null;
  source: AuthenticationSource;
}

/**
 * Consolidated auth state for the application, returning the best available token
 * available.
 *
 * Returns tokens according to the behavior of the respective authentication providers,
 * e.g. DeepLinkAuthorization will return tokens when the current route maps to a saved
 * token, while Cognito will return tokens when the user is logged in.
 *
 * Precedence:
 *
 * 1. Authentication via user/password login (Cognito) will always be returned in present.
 * 2. If user is not logged in, a Deep Link token will be returned if available.
 *
 * @returns
 */
export const useAuthentication = (): AuthenticationState => {
  const cognito = useCognitoSession();
  const deepLink = useDeepLinkAuthorization();

  // HACK: CRE-3133. When migrating to Cognito, we replaced with useAuthAuthentication hook,
  // which used a reducer pattern and required multiple render cycles to resolve the auth state,
  // with the new useCognitoSession, which resolves in one cycle. As a result, some tests began
  // to fail due to Apollo mock operations being triggered twice. The closest isolation we could
  // get to a cause was the extra render in this hook, and restoring the delay with this redundant
  // setState/useEffect combo fixes the tests. The bug attached to this is to remove this once we
  // have capacity for deeper debugging.
  const [ticked, setTicked] = useState(false);
  useEffect(() => {
    if (!ticked) {
      setTicked(true);
    }
  }, [ticked]);

  // We must wait for all providers to be resolved before returning a token;
  // we don't want to return a DeepLink token from session storage, if the
  // logged in session is still pending.
  const isReady = ticked && !deepLink.loading && !cognito.loading;

  if (!isReady) {
    return {
      isLoading: true,
      isReady: false,
      authenticated: false,
      authToken: null,
      hasToken: false,
      error: null,
      source: AuthenticationSource.None,
    };
  }

  // If an Cognito token is available, user is logged in.
  if (cognito.token) {
    return {
      isLoading: false,
      isReady: true,
      authenticated: true,
      authToken: cognito.token,
      hasToken: true,
      error: null,
      source: AuthenticationSource.UserAccountAuthentication,
    };
  }

  // If a Deep Link token is available, user has access to the current route.
  if (deepLink.token) {
    return {
      isLoading: false,
      isReady: true,
      authenticated: true,
      authToken: deepLink.token,
      hasToken: true,
      error: null,
      source: AuthenticationSource.DeepLink,
    };
  }

  // Otherwise, auth providers are resolved and no token is available.
  // User is not logged in.
  return {
    isLoading: false,
    isReady: true,
    authenticated: false,
    authToken: null,
    hasToken: false,
    error: cognito.error || null,
    source: AuthenticationSource.None,
  };
};

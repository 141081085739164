import { PropsWithChildren, useContext, type FunctionComponent } from 'react';
import {
  TenantConfigContext,
  TenantConfiguration,
} from './TenantConfigContext';
import useTenantDomain from '@/hooks/useTenantDomain';
import { SchedulingType } from '@/lib/graphql/types';
import { useTenantConfigQuery } from '@/lib/graphql/shared';

export interface TenantConfigProps extends PropsWithChildren {
  perspective: 'staff' | 'patient';
}

/**
 * Handles loading the tenant configuration for the current domain/subdomain.
 *
 * Uses the useTenantDomain hook, so tenant domain can be set in local/staging
 * environments using the TENANT_DOMAIN environment variable.
 *
 * @see MockTenantConfigProvider for testing.
 */
export const TenantConfigProvider: FunctionComponent<TenantConfigProps> = ({
  children,
  perspective,
}) => {
  const { domain } = useTenantDomain();
  const { data } = useTenantConfigQuery({
    skip: !domain,
    variables: {
      domain: domain!,
    },
  });
  const tenant = data?.tenantByDomain;

  if (!domain || !tenant) {
    return (
      <TenantConfigContext.Provider value={null}>
        {children}
      </TenantConfigContext.Provider>
    );
  }

  const cognitoConfig = (tenant.cognitoConfig &&
    (perspective === 'staff'
      ? {
          clientId: tenant.cognitoConfig?.staffClientId,
          userPoolId: tenant.cognitoConfig?.staffUserPoolId,
        }
      : {
          clientId: tenant.cognitoConfig?.patientsClientId,
          userPoolId: tenant.cognitoConfig?.patientsUserPoolId,
        })) || {
    clientId: '',
    userPoolId: '',
  };

  const config = {
    name: tenant.name,
    slug: tenant.slug,
    features: {
      enableCronofyScheduling:
        tenant.schedulingType === SchedulingType.Integrated,
      enableNonIntegratedScheduling:
        tenant.schedulingType === SchedulingType.NonIntegratedProviderSearch,
    },
    cognito: cognitoConfig,
  };

  return (
    <TenantConfigContext.Provider value={config}>
      {children}
    </TenantConfigContext.Provider>
  );
};

type TenantConfigReturnValue =
  | ({ loading: true } & Partial<TenantConfiguration>)
  | ({ loading: false } & TenantConfiguration);

export const useTenantConfig = (): TenantConfigReturnValue => {
  const ctx = useContext(TenantConfigContext);

  if (ctx === null) {
    return {
      loading: true,
    };
  }

  return {
    loading: false,
    ...ctx,
  };
};

import type { FunctionComponent, PropsWithChildren } from 'react';
import { ApolloProvider } from '@apollo/client';
import createApolloClient from '@/lib/graphql/apollo-client';
import { useCurrentProgramContext } from '@/contexts/ProgramSwitch';
import { useAuthentication } from '@/contexts/Authentication';

const AuthenticatedApolloProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { authToken } = useAuthentication();
  const { currentProgramId } = useCurrentProgramContext();

  const client = createApolloClient(authToken ?? '', currentProgramId);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthenticatedApolloProvider;

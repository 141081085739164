import { TaskRelatedObject } from '@/lib/graphql/types';
import { ParsedUrlQuery } from 'querystring';

/**
 * Given a public path, extract the related object type and ID for a task object
 *
 * @param pathname The path to parse
 *
 * @example /content/guide/?guidedJourneyId=123 -> { objectType: TaskRelatedObject.Content, objectId: 'GuidedJourney:123' }
 * @example /content/view/?contentId=123 -> { objectType: TaskRelatedObject.Content, objectId: 'Page:123' }
 * @example /form/?formId=123 -> { objectType: TaskRelatedObject.Form, objectId: '123' }
 */
export const objectForPathname = (
  pathname: string,
  query: ParsedUrlQuery
): {
  objectType: TaskRelatedObject;
  objectId: string | null;
} | null => {
  if (pathname.startsWith('/content/guide') && query.guidedJourneyId) {
    return {
      objectType: TaskRelatedObject.Content,
      objectId: `GuidedJourney:${query.guidedJourneyId}`,
    };
  }

  if (pathname.startsWith('/content/view') && query.contentId) {
    return {
      objectType: TaskRelatedObject.Content,
      objectId: `Page:${query.contentId}`,
    };
  }

  if (pathname.startsWith('/form') && query.formId) {
    return {
      objectType: TaskRelatedObject.Form,
      objectId: `${query.formId}`,
    };
  }

  if (pathname.startsWith('/scheduling/') && query.appointmentTypeId) {
    return {
      objectType: TaskRelatedObject.AppointmentType,
      objectId: `${query.appointmentTypeId}`,
    };
  }

  if (pathname.startsWith('/provider-search')) {
    return {
      objectType: TaskRelatedObject.Specialty,
      objectId: query.specialty ? `${query.specialty}` : null,
    };
  }

  return null;
};

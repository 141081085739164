import {
  type PropsWithChildren,
  createContext,
  useContext,
  type FunctionComponent,
} from 'react';
import * as staticFeatures from '@/lib/utils/features';
import { useTenantConfig } from '@/contexts/TenantConfig';

interface FeatureFlagContext extends Record<string, boolean> {
  isReady: boolean;
}

export interface FeatureFlagProps extends PropsWithChildren {
  overrides?: Record<string, boolean>;
}

const FeatureFlagContext = createContext<FeatureFlagContext>({
  isReady: false,
});

export const FeatureFlagProvider: FunctionComponent<FeatureFlagProps> = ({
  overrides = {},
  children,
}) => {
  const { loading: tenantLoading, features: tenantFeatures = {} } =
    useTenantConfig();

  const flags = {
    isReady: !tenantLoading,
    ...staticFeatures,
    ...tenantFeatures,
    // TODO: CRE-3049 -- integrate remote program features
    // ...programFeatures,
    ...overrides,
  };

  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

/**
 * Access a feature flag by name.
 * @param flag The name of the feature flag
 * @returns The value of the feature flag, or undefined if the flag dictionary is not ready yet (e.g. remote config flags are still loading.)
 */
export function useFeatureFlag<F extends string>(flag: F): boolean | undefined;

/**
 * Access a feature flag by name.
 * @param flags An array of feature flag names
 * @returns The values of the feature flags, or undefined if the flag dictionary is not ready yet (e.g. remote config flags are still loading.)
 */
export function useFeatureFlag<F extends string>(
  ...flags: F[]
): Record<F, boolean> | undefined;

export function useFeatureFlag<F extends string>(
  ...input: F[]
): boolean | Record<F, boolean> | undefined {
  const flags = useContext(FeatureFlagContext);

  if (!flags.isReady) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    input.forEach(
      (_) =>
        !(_ in flags) && console.error(`ERROR: Feature flag "${_}" not found.`)
    );
  }

  if (input.length === 1) {
    return !!flags[input[0]];
  }

  // NB. Because fromEntries type is always Record<string, T>, we have to cast  to
  // Record<F, ...> to maintain the return type.
  return Object.fromEntries(input.map((_) => [_, !!flags[_]])) as Record<
    F,
    boolean
  >;
}

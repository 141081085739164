import { useEffect, useState } from 'react';

/**
 * The tenant domain is used to look up the tenant configuration. In production environments,
 * this would be the domain of the current request. In local testing, we fall back to the
 * TENANT_DOMAIN environment variable to allow for testing multiple tenants.
 *
 * To support this in different build scenarios, we check the environment variable first and then
 * fall back to the live domain if it is not set.
 *
 * @returns
 */
const useTenantDomain = () => {
  const envOverrideDomain = process.env.NEXT_PUBLIC_TENANT_DOMAIN;
  const [domain, setDomain] = useState<string | null>(
    envOverrideDomain || null
  );

  useEffect(() => {
    if (!envOverrideDomain) {
      setDomain(window.location.hostname);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps
     -- no dependencies, run once on application start. */
  }, []);

  return {
    loading: !domain,
    domain,
  };
};

export default useTenantDomain;

/*
 * This file is generated by graphql-codegen. Do NOT edit directly.
 * Run `npm run graphql:caire-api` to regenerate.
 */
import * as ServiceTypes from '@/lib/graphql/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = { ssr: false, fetchPolicy: 'network-only' } as const;
export type AiConversationSummaryFragment = {
  __typename?: 'AgentConversation';
  id: any;
  programId?: any | null;
  patientId?: any | null;
  status: ServiceTypes.AgentConversationStatus;
  channelType: ServiceTypes.ChannelType;
  channelId: any;
  lastMessageAt: any;
  lastMessagePreview: string;
  lastMessageSenderType: ServiceTypes.ParticipantType;
  updatedAt: any;
  deletedAt?: any | null;
  numMessages: number;
  messages: Array<{
    __typename?: 'AgentMessage';
    id: any;
    programId?: any | null;
    patientId?: any | null;
    conversationId: any;
    recipientId?: any | null;
    recipientType: ServiceTypes.ParticipantType;
    senderId?: any | null;
    senderType: ServiceTypes.ParticipantType;
    body: string;
    createdAt: any;
    deletedAt?: any | null;
    responseId?: any | null;
    inferenceResult?: {
      __typename?: 'InferenceResult';
      intents?: Array<{
        __typename?: 'Intent';
        name: string;
        confidence: number;
      }> | null;
    } | null;
    expectation?: {
      __typename?: 'BotResponseExpectation';
      strict: boolean;
      type?: ServiceTypes.ExpectationType | null;
      hint?: ServiceTypes.ExpectationHint | null;
      options?: Array<{
        __typename?: 'BotResponseOption';
        text: string;
        value: string;
        type?: string | null;
        id?: string | null;
      }> | null;
    } | null;
  }>;
};

export type AiMessageExpectationFragment = {
  __typename?: 'BotResponseExpectation';
  strict: boolean;
  type?: ServiceTypes.ExpectationType | null;
  hint?: ServiceTypes.ExpectationHint | null;
  options?: Array<{
    __typename?: 'BotResponseOption';
    text: string;
    value: string;
    type?: string | null;
    id?: string | null;
  }> | null;
};

export type AiMessageExpectationOptionFragment = {
  __typename?: 'BotResponseOption';
  text: string;
  value: string;
  type?: string | null;
  id?: string | null;
};

export type AiMessageFragment = {
  __typename?: 'AgentMessage';
  id: any;
  programId?: any | null;
  patientId?: any | null;
  conversationId: any;
  recipientId?: any | null;
  recipientType: ServiceTypes.ParticipantType;
  senderId?: any | null;
  senderType: ServiceTypes.ParticipantType;
  body: string;
  createdAt: any;
  deletedAt?: any | null;
  responseId?: any | null;
  inferenceResult?: {
    __typename?: 'InferenceResult';
    intents?: Array<{
      __typename?: 'Intent';
      name: string;
      confidence: number;
    }> | null;
  } | null;
  expectation?: {
    __typename?: 'BotResponseExpectation';
    strict: boolean;
    type?: ServiceTypes.ExpectationType | null;
    hint?: ServiceTypes.ExpectationHint | null;
    options?: Array<{
      __typename?: 'BotResponseOption';
      text: string;
      value: string;
      type?: string | null;
      id?: string | null;
    }> | null;
  } | null;
};

export type AiIntentFragment = {
  __typename?: 'Intent';
  name: string;
  confidence: number;
};

export type MessageResponseFragment = {
  __typename?: 'BotResponse';
  id: string;
  thread: string;
  text: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  expectation?: {
    __typename?: 'BotResponseExpectation';
    strict: boolean;
    type?: ServiceTypes.ExpectationType | null;
    hint?: ServiceTypes.ExpectationHint | null;
    options?: Array<{
      __typename?: 'BotResponseOption';
      text: string;
      value: string;
      type?: string | null;
      id?: string | null;
    }> | null;
  } | null;
};

export type ProfessionalLicenseInfoFragment = {
  __typename?: 'ProfessionalLicense';
  id: string;
  type: ServiceTypes.ProfessionalLicenseType;
  licenseState: ServiceTypes.StateCode;
  licenseNumber: string;
  status: ServiceTypes.ProfessionalLicenseStatus;
  expirationDate?: any | null;
  description?: string | null;
};

export type CreateAiMessageMutationVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  webContext: ServiceTypes.Scalars['String']['input'];
  text: ServiceTypes.Scalars['String']['input'];
}>;

export type CreateAiMessageMutation = {
  __typename?: 'Mutation';
  createWebAgentMessage: {
    __typename?: 'AgentMessage';
    id: any;
    programId?: any | null;
    patientId?: any | null;
    conversationId: any;
    recipientId?: any | null;
    recipientType: ServiceTypes.ParticipantType;
    senderId?: any | null;
    senderType: ServiceTypes.ParticipantType;
    body: string;
    createdAt: any;
    deletedAt?: any | null;
    responseId?: any | null;
    inferenceResult?: {
      __typename?: 'InferenceResult';
      intents?: Array<{
        __typename?: 'Intent';
        name: string;
        confidence: number;
      }> | null;
    } | null;
    expectation?: {
      __typename?: 'BotResponseExpectation';
      strict: boolean;
      type?: ServiceTypes.ExpectationType | null;
      hint?: ServiceTypes.ExpectationHint | null;
      options?: Array<{
        __typename?: 'BotResponseOption';
        text: string;
        value: string;
        type?: string | null;
        id?: string | null;
      }> | null;
    } | null;
  };
};

export type AiConversationQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  webContext: ServiceTypes.Scalars['String']['input'];
  pagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
}>;

export type AiConversationQuery = {
  __typename?: 'Query';
  activeWebConversation?: {
    __typename?: 'AgentConversation';
    id: any;
    programId?: any | null;
    patientId?: any | null;
    status: ServiceTypes.AgentConversationStatus;
    channelType: ServiceTypes.ChannelType;
    channelId: any;
    lastMessageAt: any;
    lastMessagePreview: string;
    lastMessageSenderType: ServiceTypes.ParticipantType;
    updatedAt: any;
    deletedAt?: any | null;
    numMessages: number;
    messages: Array<{
      __typename?: 'AgentMessage';
      id: any;
      programId?: any | null;
      patientId?: any | null;
      conversationId: any;
      recipientId?: any | null;
      recipientType: ServiceTypes.ParticipantType;
      senderId?: any | null;
      senderType: ServiceTypes.ParticipantType;
      body: string;
      createdAt: any;
      deletedAt?: any | null;
      responseId?: any | null;
      inferenceResult?: {
        __typename?: 'InferenceResult';
        intents?: Array<{
          __typename?: 'Intent';
          name: string;
          confidence: number;
        }> | null;
      } | null;
      expectation?: {
        __typename?: 'BotResponseExpectation';
        strict: boolean;
        type?: ServiceTypes.ExpectationType | null;
        hint?: ServiceTypes.ExpectationHint | null;
        options?: Array<{
          __typename?: 'BotResponseOption';
          text: string;
          value: string;
          type?: string | null;
          id?: string | null;
        }> | null;
      } | null;
    }>;
  } | null;
};

export type AllergyAutocompleteQueryVariables = ServiceTypes.Exact<{
  query: ServiceTypes.Scalars['String']['input'];
}>;

export type AllergyAutocompleteQuery = {
  __typename?: 'Query';
  allergyAutocomplete: Array<{
    __typename?: 'AllergyAutocompleteResult';
    name: string;
    rxcui: number;
    genericRxcui: number;
  }>;
};

export type CareJourneysQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FilterCareJourneyParams;
}>;

export type CareJourneysQuery = {
  __typename?: 'Query';
  careJourneys: Array<{
    __typename?: 'CareJourney';
    id: any;
    programId: any;
    status: ServiceTypes.CareJourneyStatus;
    previousCareJourneyId?: any | null;
    note?: string | null;
    offboardedAt?: any | null;
    retiredAt?: any | null;
    createdAt: any;
    careJourneyTemplateVersion: {
      __typename?: 'CareJourneyTemplateVersion';
      id: any;
      duration: number;
      careJourneyTemplate: {
        __typename?: 'CareJourneyTemplate';
        id: any;
        name: string;
        slug: string;
      };
    };
    sequentialTouchpoints?: Array<{
      __typename?: 'SequentialTouchpoint';
      id: any;
      labelDate?: any | null;
      dueAt?: any | null;
      completedAt?: any | null;
      position: number;
      note?: string | null;
      status: ServiceTypes.TouchpointStatus;
      sequentialTouchpointTemplate?: {
        __typename?: 'SequentialTouchpointTemplate';
        category: ServiceTypes.TouchpointCategory;
        type?: ServiceTypes.TouchpointType | null;
        name: string;
      } | null;
    }> | null;
  }>;
};

export type MedicationAutocompleteQueryVariables = ServiceTypes.Exact<{
  query: ServiceTypes.Scalars['String']['input'];
}>;

export type MedicationAutocompleteQuery = {
  __typename?: 'Query';
  medicationAutocomplete: {
    __typename?: 'MedicationAutocompleteResult';
    names: Array<string>;
  };
};

export type MedicationLookupQueryVariables = ServiceTypes.Exact<{
  query: ServiceTypes.Scalars['String']['input'];
}>;

export type MedicationLookupQuery = {
  __typename?: 'Query';
  medicationLookup: Array<{
    __typename?: 'MedicationLookupResult';
    rxcui: number;
    strength: string;
    displayName: string;
  }>;
};

export type PatientQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type PatientQuery = {
  __typename?: 'Query';
  patient: {
    __typename?: 'Patient';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    birthDate: any;
    addresses?: Array<{
      __typename?: 'Address';
      city: string;
      state: ServiceTypes.StateCode;
    }> | null;
  };
};

export type ListProgramsQueryVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type ListProgramsQuery = {
  __typename?: 'Query';
  programs: Array<{
    __typename?: 'Program';
    id: any;
    name: string;
    slug: string;
    config?: {
      __typename?: 'ProgramConfigResponse';
      roleNameMapping?: any | null;
    } | null;
  }>;
};

export type AppointmentTypeQueryVariables = ServiceTypes.Exact<{
  appointmentTypeId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type AppointmentTypeQuery = {
  __typename?: 'Query';
  appointmentType: {
    __typename?: 'AppointmentType';
    id: any;
    programId: any;
    name: string;
    slug: string;
    displayName: string;
    duration: number;
    descriptionTemplate: string;
    allowedRole: ServiceTypes.StaffRole;
    postFormId?: any | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type ListAppointmentsQueryVariables = ServiceTypes.Exact<{
  input?: ServiceTypes.InputMaybe<ServiceTypes.ListAppointmentsInput>;
}>;

export type ListAppointmentsQuery = {
  __typename?: 'Query';
  appointments: Array<{
    __typename?: 'Appointment';
    id: any;
    programId: any;
    videoConferenceUrl?: string | null;
    status: ServiceTypes.AppointmentStatus;
    startTime: any;
    description: string;
    endTime: any;
    summary: string;
    taskId?: any | null;
    timezone: string;
    appointmentType: {
      __typename?: 'AppointmentType';
      id: any;
      slug: string;
      name: string;
      allowedRole: ServiceTypes.StaffRole;
      displayName: string;
      duration: number;
    };
    staff: {
      __typename?: 'Staff';
      id: any;
      firstName: string;
      lastName: string;
      bio?: string | null;
      preferredName?: string | null;
      role: ServiceTypes.StaffRole;
      status: ServiceTypes.StaffStatus;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        id: string;
        type: ServiceTypes.ProfessionalLicenseType;
        licenseState: ServiceTypes.StateCode;
        licenseNumber: string;
        status: ServiceTypes.ProfessionalLicenseStatus;
        expirationDate?: any | null;
        description?: string | null;
      }>;
    };
    patient: {
      __typename?: 'Patient';
      id: any;
      firstName: string;
      lastName: string;
      birthDate: any;
      preferredName?: string | null;
    };
  }>;
};

export type CancelAppointmentMutationVariables = ServiceTypes.Exact<{
  cancelAppointmentId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CancelAppointmentMutation = {
  __typename?: 'Mutation';
  cancelAppointment: { __typename?: 'Appointment'; id: any };
};

export type CreateCronofyElementTokenMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateCronofyElementTokenInput;
}>;

export type CreateCronofyElementTokenMutation = {
  __typename?: 'Mutation';
  createCronofyElementToken: {
    __typename?: 'CronofyElementToken';
    token: string;
    externalUserId: string;
  };
};

export type StaffQueryVariables = ServiceTypes.Exact<{
  staffId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type StaffQuery = {
  __typename?: 'Query';
  staff: {
    __typename?: 'Staff';
    id: any;
    createdAt: any;
    updatedAt: any;
    firstName: string;
    lastName: string;
    bio?: string | null;
    role: ServiceTypes.StaffRole;
    profileImageUrl?: string | null;
    preferredName?: string | null;
    programs: Array<{ __typename?: 'StaffProgram'; programId: any }>;
  };
};

export type StaffsQueryVariables = ServiceTypes.Exact<{
  input?: ServiceTypes.InputMaybe<ServiceTypes.ListStaffsInput>;
}>;

export type StaffsQuery = {
  __typename?: 'Query';
  staffs: Array<{
    __typename?: 'Staff';
    bio?: string | null;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    profileImageUrl?: string | null;
    role: ServiceTypes.StaffRole;
    status: ServiceTypes.StaffStatus;
    createdAt: any;
    updatedAt: any;
    id: any;
    professionalLicenses: Array<{
      __typename?: 'ProfessionalLicense';
      licenseState: ServiceTypes.StateCode;
      type: ServiceTypes.ProfessionalLicenseType;
      createdAt: any;
      id: string;
      licenseNumber: string;
      status: ServiceTypes.ProfessionalLicenseStatus;
      updatedAt: any;
    }>;
    programs: Array<{
      __typename?: 'StaffProgram';
      programId: any;
      status: ServiceTypes.StaffProgramStatus;
      createdAt: any;
      id: string;
      updatedAt: any;
      program: {
        __typename?: 'Program';
        createdAt: any;
        description: string;
        id: any;
        name: string;
        slug: string;
        updatedAt: any;
        status: ServiceTypes.ProgramStatus;
      };
    }>;
  }>;
};

export type PatientTasksQueryVariables = ServiceTypes.Exact<{
  filters?: ServiceTypes.InputMaybe<ServiceTypes.FilterTaskParams>;
  pagination?: ServiceTypes.InputMaybe<ServiceTypes.TaskPaginationParams>;
  sorting?: ServiceTypes.InputMaybe<Array<ServiceTypes.SortTaskParam>>;
}>;

export type PatientTasksQuery = {
  __typename?: 'Query';
  tasks: {
    __typename?: 'TasksList';
    totalCount: number;
    items: Array<{
      __typename?: 'Task';
      id: any;
      assigneeId: any;
      reporterId?: any | null;
      patientId: any;
      comments?: string | null;
      taskTemplateId?: any | null;
      name: string;
      status: ServiceTypes.TaskStatus;
      programId: any;
      taskDueAt: any;
      eventDueAt?: any | null;
      taskExpireAt?: any | null;
      role?: ServiceTypes.StaffRole | null;
      category: ServiceTypes.TaskCategory;
      taskType: ServiceTypes.TaskType;
      priority: ServiceTypes.TaskPriority;
      description?: string | null;
      relatedObjectType?: ServiceTypes.TaskRelatedObject | null;
      relatedObjectId?: string | null;
      resultObjectType?: ServiceTypes.TaskResultObject | null;
      resultObjectId?: any | null;
      createdAt: any;
      updatedAt: any;
      assignee:
        | {
            __typename: 'Patient';
            id: any;
            firstName: string;
            lastName: string;
          }
        | { __typename: 'Staff'; id: any; firstName: string; lastName: string };
      patient: {
        __typename?: 'Patient';
        id: any;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
        birthDate: any;
      };
      program: { __typename?: 'Program'; id: any };
      taskConfig?: {
        __typename?: 'TaskConfig';
        type: ServiceTypes.TaskType;
      } | null;
      legacyNotificationSchedule?: {
        __typename?: 'NotificationSchedule';
        schedules: Array<{
          __typename?: 'NotificationReminder';
          sequentialOrder: number;
          scheduledAt: any;
          eventName: string;
        }>;
      } | null;
    }>;
  };
};

export type TenantConfigQueryVariables = ServiceTypes.Exact<{
  domain: ServiceTypes.Scalars['String']['input'];
}>;

export type TenantConfigQuery = {
  __typename?: 'Query';
  tenantByDomain?: {
    __typename?: 'PublicTenant';
    id: any;
    name: string;
    slug: string;
    schedulingType: ServiceTypes.SchedulingType;
    cognitoConfig?: {
      __typename?: 'CognitoConfig';
      patientsClientId: string;
      patientsUserPoolId: string;
      staffClientId: string;
      staffUserPoolId: string;
    } | null;
  } | null;
};

export type ConversationUserAccessTokenQueryVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type ConversationUserAccessTokenQuery = {
  __typename?: 'Query';
  conversationUserAccessToken: {
    __typename?: 'ConversationUserAccessToken';
    accessToken: string;
    expiredAt: number;
  };
};

export const AiIntentFragmentDoc = gql`
  fragment AiIntent on Intent {
    name
    confidence
  }
`;
export const AiMessageExpectationOptionFragmentDoc = gql`
  fragment AiMessageExpectationOption on BotResponseOption {
    text
    value
    type
    id
  }
`;
export const AiMessageExpectationFragmentDoc = gql`
  fragment AiMessageExpectation on BotResponseExpectation {
    strict
    type
    hint
    options {
      ...AiMessageExpectationOption
    }
  }
  ${AiMessageExpectationOptionFragmentDoc}
`;
export const AiMessageFragmentDoc = gql`
  fragment AiMessage on AgentMessage {
    id
    programId
    patientId
    conversationId
    recipientId
    recipientType
    senderId
    senderType
    body
    createdAt
    deletedAt
    inferenceResult {
      intents {
        ...AiIntent
      }
    }
    responseId
    expectation {
      ...AiMessageExpectation
    }
  }
  ${AiIntentFragmentDoc}
  ${AiMessageExpectationFragmentDoc}
`;
export const AiConversationSummaryFragmentDoc = gql`
  fragment AiConversationSummary on AgentConversation {
    id
    programId
    patientId
    status
    channelType
    channelId
    lastMessageAt
    lastMessagePreview
    lastMessageSenderType
    updatedAt
    deletedAt
    messages(pagination: $pagination) {
      ...AiMessage
    }
    numMessages
  }
  ${AiMessageFragmentDoc}
`;
export const MessageResponseFragmentDoc = gql`
  fragment MessageResponse on BotResponse {
    id
    thread
    text
    createdAt
    updatedAt
    expectation {
      strict
      type
      hint
      options {
        text
        value
        type
        id
      }
    }
  }
`;
export const ProfessionalLicenseInfoFragmentDoc = gql`
  fragment ProfessionalLicenseInfo on ProfessionalLicense {
    id
    type
    licenseState
    licenseNumber
    status
    expirationDate
    description
  }
`;
export const CreateAiMessageDocument = gql`
  mutation CreateAiMessage(
    $patientId: UUID!
    $programId: UUID!
    $webContext: String!
    $text: String!
  ) {
    createWebAgentMessage(
      input: {
        patientId: $patientId
        programId: $programId
        webContext: $webContext
        body: $text
      }
    ) {
      ...AiMessage
    }
  }
  ${AiMessageFragmentDoc}
`;
export type CreateAiMessageMutationFn = Apollo.MutationFunction<
  CreateAiMessageMutation,
  CreateAiMessageMutationVariables
>;

/**
 * __useCreateAiMessageMutation__
 *
 * To run a mutation, you first call `useCreateAiMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiMessageMutation, { data, loading, error }] = useCreateAiMessageMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      webContext: // value for 'webContext'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateAiMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiMessageMutation,
    CreateAiMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAiMessageMutation,
    CreateAiMessageMutationVariables
  >(CreateAiMessageDocument, options);
}
export type CreateAiMessageMutationHookResult = ReturnType<
  typeof useCreateAiMessageMutation
>;
export type CreateAiMessageMutationResult =
  Apollo.MutationResult<CreateAiMessageMutation>;
export type CreateAiMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateAiMessageMutation,
  CreateAiMessageMutationVariables
>;
export const AiConversationDocument = gql`
  query AiConversation(
    $patientId: UUID!
    $programId: UUID!
    $webContext: String!
    $pagination: PaginationParams
  ) {
    activeWebConversation(
      input: {
        patientId: $patientId
        programId: $programId
        webContext: $webContext
      }
    ) {
      ...AiConversationSummary
    }
  }
  ${AiConversationSummaryFragmentDoc}
`;

/**
 * __useAiConversationQuery__
 *
 * To run a query within a React component, call `useAiConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiConversationQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      webContext: // value for 'webContext'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAiConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AiConversationQuery,
    AiConversationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AiConversationQuery, AiConversationQueryVariables>(
    AiConversationDocument,
    options
  );
}
export function useAiConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiConversationQuery,
    AiConversationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AiConversationQuery, AiConversationQueryVariables>(
    AiConversationDocument,
    options
  );
}
export type AiConversationQueryHookResult = ReturnType<
  typeof useAiConversationQuery
>;
export type AiConversationLazyQueryHookResult = ReturnType<
  typeof useAiConversationLazyQuery
>;
export type AiConversationQueryResult = Apollo.QueryResult<
  AiConversationQuery,
  AiConversationQueryVariables
>;
export const AllergyAutocompleteDocument = gql`
  query AllergyAutocomplete($query: String!) {
    allergyAutocomplete(query: $query) {
      name
      rxcui
      genericRxcui
    }
  }
`;

/**
 * __useAllergyAutocompleteQuery__
 *
 * To run a query within a React component, call `useAllergyAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllergyAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllergyAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllergyAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllergyAutocompleteQuery,
    AllergyAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllergyAutocompleteQuery,
    AllergyAutocompleteQueryVariables
  >(AllergyAutocompleteDocument, options);
}
export function useAllergyAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllergyAutocompleteQuery,
    AllergyAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllergyAutocompleteQuery,
    AllergyAutocompleteQueryVariables
  >(AllergyAutocompleteDocument, options);
}
export type AllergyAutocompleteQueryHookResult = ReturnType<
  typeof useAllergyAutocompleteQuery
>;
export type AllergyAutocompleteLazyQueryHookResult = ReturnType<
  typeof useAllergyAutocompleteLazyQuery
>;
export type AllergyAutocompleteQueryResult = Apollo.QueryResult<
  AllergyAutocompleteQuery,
  AllergyAutocompleteQueryVariables
>;
export const CareJourneysDocument = gql`
  query CareJourneys($filters: FilterCareJourneyParams!) {
    careJourneys(filters: $filters) {
      id
      programId
      status
      previousCareJourneyId
      note
      offboardedAt
      retiredAt
      createdAt
      careJourneyTemplateVersion {
        id
        duration
        careJourneyTemplate {
          id
          name
          slug
        }
      }
      sequentialTouchpoints {
        id
        labelDate
        dueAt
        completedAt
        position
        note
        status
        sequentialTouchpointTemplate {
          category
          type
          name
        }
      }
    }
  }
`;

/**
 * __useCareJourneysQuery__
 *
 * To run a query within a React component, call `useCareJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareJourneysQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCareJourneysQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareJourneysQuery,
    CareJourneysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareJourneysQuery, CareJourneysQueryVariables>(
    CareJourneysDocument,
    options
  );
}
export function useCareJourneysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareJourneysQuery,
    CareJourneysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareJourneysQuery, CareJourneysQueryVariables>(
    CareJourneysDocument,
    options
  );
}
export type CareJourneysQueryHookResult = ReturnType<
  typeof useCareJourneysQuery
>;
export type CareJourneysLazyQueryHookResult = ReturnType<
  typeof useCareJourneysLazyQuery
>;
export type CareJourneysQueryResult = Apollo.QueryResult<
  CareJourneysQuery,
  CareJourneysQueryVariables
>;
export const MedicationAutocompleteDocument = gql`
  query MedicationAutocomplete($query: String!) {
    medicationAutocomplete(query: $query) {
      names
    }
  }
`;

/**
 * __useMedicationAutocompleteQuery__
 *
 * To run a query within a React component, call `useMedicationAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMedicationAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedicationAutocompleteQuery,
    MedicationAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedicationAutocompleteQuery,
    MedicationAutocompleteQueryVariables
  >(MedicationAutocompleteDocument, options);
}
export function useMedicationAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedicationAutocompleteQuery,
    MedicationAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedicationAutocompleteQuery,
    MedicationAutocompleteQueryVariables
  >(MedicationAutocompleteDocument, options);
}
export type MedicationAutocompleteQueryHookResult = ReturnType<
  typeof useMedicationAutocompleteQuery
>;
export type MedicationAutocompleteLazyQueryHookResult = ReturnType<
  typeof useMedicationAutocompleteLazyQuery
>;
export type MedicationAutocompleteQueryResult = Apollo.QueryResult<
  MedicationAutocompleteQuery,
  MedicationAutocompleteQueryVariables
>;
export const MedicationLookupDocument = gql`
  query MedicationLookup($query: String!) {
    medicationLookup(query: $query) {
      rxcui
      strength
      displayName
    }
  }
`;

/**
 * __useMedicationLookupQuery__
 *
 * To run a query within a React component, call `useMedicationLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationLookupQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMedicationLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedicationLookupQuery,
    MedicationLookupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicationLookupQuery, MedicationLookupQueryVariables>(
    MedicationLookupDocument,
    options
  );
}
export function useMedicationLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedicationLookupQuery,
    MedicationLookupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedicationLookupQuery,
    MedicationLookupQueryVariables
  >(MedicationLookupDocument, options);
}
export type MedicationLookupQueryHookResult = ReturnType<
  typeof useMedicationLookupQuery
>;
export type MedicationLookupLazyQueryHookResult = ReturnType<
  typeof useMedicationLookupLazyQuery
>;
export type MedicationLookupQueryResult = Apollo.QueryResult<
  MedicationLookupQuery,
  MedicationLookupQueryVariables
>;
export const PatientDocument = gql`
  query Patient($patientId: UUID!) {
    patient(id: $patientId) {
      id
      firstName
      lastName
      preferredName
      birthDate
      addresses {
        city
        state
      }
    }
  }
`;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientQuery(
  baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientQuery, PatientQueryVariables>(
    PatientDocument,
    options
  );
}
export function usePatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(
    PatientDocument,
    options
  );
}
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientQueryResult = Apollo.QueryResult<
  PatientQuery,
  PatientQueryVariables
>;
export const ListProgramsDocument = gql`
  query ListPrograms {
    programs {
      id
      name
      slug
      config {
        roleNameMapping
      }
    }
  }
`;

/**
 * __useListProgramsQuery__
 *
 * To run a query within a React component, call `useListProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProgramsQuery,
    ListProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListProgramsQuery, ListProgramsQueryVariables>(
    ListProgramsDocument,
    options
  );
}
export function useListProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProgramsQuery,
    ListProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListProgramsQuery, ListProgramsQueryVariables>(
    ListProgramsDocument,
    options
  );
}
export type ListProgramsQueryHookResult = ReturnType<
  typeof useListProgramsQuery
>;
export type ListProgramsLazyQueryHookResult = ReturnType<
  typeof useListProgramsLazyQuery
>;
export type ListProgramsQueryResult = Apollo.QueryResult<
  ListProgramsQuery,
  ListProgramsQueryVariables
>;
export const AppointmentTypeDocument = gql`
  query AppointmentType($appointmentTypeId: UUID!) {
    appointmentType(id: $appointmentTypeId) {
      id
      programId
      name
      slug
      displayName
      duration
      descriptionTemplate
      allowedRole
      postFormId
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useAppointmentTypeQuery__
 *
 * To run a query within a React component, call `useAppointmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentTypeQuery({
 *   variables: {
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *   },
 * });
 */
export function useAppointmentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentTypeQuery,
    AppointmentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentTypeQuery, AppointmentTypeQueryVariables>(
    AppointmentTypeDocument,
    options
  );
}
export function useAppointmentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentTypeQuery,
    AppointmentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentTypeQuery,
    AppointmentTypeQueryVariables
  >(AppointmentTypeDocument, options);
}
export type AppointmentTypeQueryHookResult = ReturnType<
  typeof useAppointmentTypeQuery
>;
export type AppointmentTypeLazyQueryHookResult = ReturnType<
  typeof useAppointmentTypeLazyQuery
>;
export type AppointmentTypeQueryResult = Apollo.QueryResult<
  AppointmentTypeQuery,
  AppointmentTypeQueryVariables
>;
export const ListAppointmentsDocument = gql`
  query ListAppointments($input: ListAppointmentsInput) {
    appointments(input: $input) {
      id
      programId
      videoConferenceUrl
      appointmentType {
        id
        slug
        name
        allowedRole
        displayName
        duration
      }
      staff {
        id
        firstName
        lastName
        bio
        preferredName
        professionalLicenses {
          ...ProfessionalLicenseInfo
        }
        role
        status
      }
      status
      startTime
      description
      endTime
      summary
      taskId
      timezone
      patient {
        id
        firstName
        lastName
        birthDate
        preferredName
      }
    }
  }
  ${ProfessionalLicenseInfoFragmentDoc}
`;

/**
 * __useListAppointmentsQuery__
 *
 * To run a query within a React component, call `useListAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAppointmentsQuery,
    ListAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAppointmentsQuery, ListAppointmentsQueryVariables>(
    ListAppointmentsDocument,
    options
  );
}
export function useListAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAppointmentsQuery,
    ListAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAppointmentsQuery,
    ListAppointmentsQueryVariables
  >(ListAppointmentsDocument, options);
}
export type ListAppointmentsQueryHookResult = ReturnType<
  typeof useListAppointmentsQuery
>;
export type ListAppointmentsLazyQueryHookResult = ReturnType<
  typeof useListAppointmentsLazyQuery
>;
export type ListAppointmentsQueryResult = Apollo.QueryResult<
  ListAppointmentsQuery,
  ListAppointmentsQueryVariables
>;
export const CancelAppointmentDocument = gql`
  mutation cancelAppointment($cancelAppointmentId: UUID!) {
    cancelAppointment(id: $cancelAppointmentId) {
      id
    }
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      cancelAppointmentId: // value for 'cancelAppointmentId'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, options);
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>;
export type CancelAppointmentMutationResult =
  Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const CreateCronofyElementTokenDocument = gql`
  mutation CreateCronofyElementToken($input: CreateCronofyElementTokenInput!) {
    createCronofyElementToken(input: $input) {
      token
      externalUserId
    }
  }
`;
export type CreateCronofyElementTokenMutationFn = Apollo.MutationFunction<
  CreateCronofyElementTokenMutation,
  CreateCronofyElementTokenMutationVariables
>;

/**
 * __useCreateCronofyElementTokenMutation__
 *
 * To run a mutation, you first call `useCreateCronofyElementTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCronofyElementTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCronofyElementTokenMutation, { data, loading, error }] = useCreateCronofyElementTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCronofyElementTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCronofyElementTokenMutation,
    CreateCronofyElementTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCronofyElementTokenMutation,
    CreateCronofyElementTokenMutationVariables
  >(CreateCronofyElementTokenDocument, options);
}
export type CreateCronofyElementTokenMutationHookResult = ReturnType<
  typeof useCreateCronofyElementTokenMutation
>;
export type CreateCronofyElementTokenMutationResult =
  Apollo.MutationResult<CreateCronofyElementTokenMutation>;
export type CreateCronofyElementTokenMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCronofyElementTokenMutation,
    CreateCronofyElementTokenMutationVariables
  >;
export const StaffDocument = gql`
  query Staff($staffId: UUID!) {
    staff(id: $staffId) {
      id
      createdAt
      updatedAt
      firstName
      lastName
      bio
      role
      profileImageUrl
      preferredName
      programs {
        programId
      }
    }
  }
`;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useStaffQuery(
  baseOptions: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaffQuery, StaffQueryVariables>(
    StaffDocument,
    options
  );
}
export function useStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(
    StaffDocument,
    options
  );
}
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffQueryResult = Apollo.QueryResult<
  StaffQuery,
  StaffQueryVariables
>;
export const StaffsDocument = gql`
  query Staffs($input: ListStaffsInput) {
    staffs(input: $input) {
      bio
      firstName
      lastName
      preferredName
      professionalLicenses {
        licenseState
        type
        createdAt
        id
        licenseNumber
        status
        updatedAt
      }
      profileImageUrl
      programs {
        programId
        status
        createdAt
        id
        updatedAt
        program {
          createdAt
          description
          id
          name
          slug
          updatedAt
          status
        }
      }
      role
      status
      createdAt
      updatedAt
      id
    }
  }
`;

/**
 * __useStaffsQuery__
 *
 * To run a query within a React component, call `useStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStaffsQuery(
  baseOptions?: Apollo.QueryHookOptions<StaffsQuery, StaffsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaffsQuery, StaffsQueryVariables>(
    StaffsDocument,
    options
  );
}
export function useStaffsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StaffsQuery, StaffsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaffsQuery, StaffsQueryVariables>(
    StaffsDocument,
    options
  );
}
export type StaffsQueryHookResult = ReturnType<typeof useStaffsQuery>;
export type StaffsLazyQueryHookResult = ReturnType<typeof useStaffsLazyQuery>;
export type StaffsQueryResult = Apollo.QueryResult<
  StaffsQuery,
  StaffsQueryVariables
>;
export const PatientTasksDocument = gql`
  query PatientTasks(
    $filters: FilterTaskParams
    $pagination: TaskPaginationParams
    $sorting: [SortTaskParam!]
  ) {
    tasks(filters: $filters, pagination: $pagination, sorting: $sorting) {
      items {
        id
        assigneeId
        assignee {
          ... on Patient {
            id
            firstName
            lastName
            __typename
          }
          ... on Staff {
            id
            firstName
            lastName
            __typename
          }
        }
        patient {
          id
          firstName
          lastName
          preferredName
          birthDate
        }
        program {
          id
        }
        reporterId
        patientId
        comments
        taskTemplateId
        name
        status
        programId
        taskDueAt
        eventDueAt
        taskExpireAt
        role
        taskConfig {
          type
        }
        category
        taskType
        priority
        description
        relatedObjectType
        relatedObjectId
        resultObjectType
        resultObjectId
        createdAt
        updatedAt
        legacyNotificationSchedule {
          schedules {
            sequentialOrder
            scheduledAt
            eventName
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __usePatientTasksQuery__
 *
 * To run a query within a React component, call `usePatientTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePatientTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PatientTasksQuery,
    PatientTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientTasksQuery, PatientTasksQueryVariables>(
    PatientTasksDocument,
    options
  );
}
export function usePatientTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientTasksQuery,
    PatientTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientTasksQuery, PatientTasksQueryVariables>(
    PatientTasksDocument,
    options
  );
}
export type PatientTasksQueryHookResult = ReturnType<
  typeof usePatientTasksQuery
>;
export type PatientTasksLazyQueryHookResult = ReturnType<
  typeof usePatientTasksLazyQuery
>;
export type PatientTasksQueryResult = Apollo.QueryResult<
  PatientTasksQuery,
  PatientTasksQueryVariables
>;
export const TenantConfigDocument = gql`
  query TenantConfig($domain: String!) {
    tenantByDomain(domain: $domain) {
      id
      name
      slug
      schedulingType
      cognitoConfig {
        patientsClientId
        patientsUserPoolId
        staffClientId
        staffUserPoolId
      }
    }
  }
`;

/**
 * __useTenantConfigQuery__
 *
 * To run a query within a React component, call `useTenantConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantConfigQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useTenantConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    TenantConfigQuery,
    TenantConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantConfigQuery, TenantConfigQueryVariables>(
    TenantConfigDocument,
    options
  );
}
export function useTenantConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TenantConfigQuery,
    TenantConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantConfigQuery, TenantConfigQueryVariables>(
    TenantConfigDocument,
    options
  );
}
export type TenantConfigQueryHookResult = ReturnType<
  typeof useTenantConfigQuery
>;
export type TenantConfigLazyQueryHookResult = ReturnType<
  typeof useTenantConfigLazyQuery
>;
export type TenantConfigQueryResult = Apollo.QueryResult<
  TenantConfigQuery,
  TenantConfigQueryVariables
>;
export const ConversationUserAccessTokenDocument = gql`
  query ConversationUserAccessToken {
    conversationUserAccessToken {
      accessToken
      expiredAt
    }
  }
`;

/**
 * __useConversationUserAccessTokenQuery__
 *
 * To run a query within a React component, call `useConversationUserAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationUserAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationUserAccessTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useConversationUserAccessTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConversationUserAccessTokenQuery,
    ConversationUserAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConversationUserAccessTokenQuery,
    ConversationUserAccessTokenQueryVariables
  >(ConversationUserAccessTokenDocument, options);
}
export function useConversationUserAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversationUserAccessTokenQuery,
    ConversationUserAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConversationUserAccessTokenQuery,
    ConversationUserAccessTokenQueryVariables
  >(ConversationUserAccessTokenDocument, options);
}
export type ConversationUserAccessTokenQueryHookResult = ReturnType<
  typeof useConversationUserAccessTokenQuery
>;
export type ConversationUserAccessTokenLazyQueryHookResult = ReturnType<
  typeof useConversationUserAccessTokenLazyQuery
>;
export type ConversationUserAccessTokenQueryResult = Apollo.QueryResult<
  ConversationUserAccessTokenQuery,
  ConversationUserAccessTokenQueryVariables
>;

import type { ProgramConfig } from './types';

const demoBaseConfig: Omit<ProgramConfig, 'programId' | 'programSlug'> = {
  programName: 'Huntington Hospital',
  programTheme: 'demo',
  contentfulTag: 'platformDemoQ12024',

  privacyPolicyUrl: 'https://example.com/privacy-policy',
  termsOfServiceUrl: 'https://example.com/terms-of-service',
  alphaTermsUrl: '',
  noticeOfPrivacyPracticesUrl: '',
  telehealthConsentUrl: '',
  welkinCloudLink: '',

  supportPhoneNumber: '+11234567890',
  supportEmail: 'support@example.com',
  supportFaqUrl: 'https://example.com/faq',

  programLogo: {
    url: '/images/demo/caire-icon-blue.svg',
    height: 32,
    width: 30,
    alt: 'Caire Demo',
  },

  lightProgramLogo: {
    url: '/images/demo/caire-icon-blue.svg',
    height: 32,
    width: 30,
    alt: 'Caire Demo',
  },

  homepage: [
    {
      component: 'WelcomeWidget',
    },
  ],
};

export default demoBaseConfig;
